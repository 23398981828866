.inventory-inventory-modal {
  .ant-modal-footer {
    & > button:first-child {
      margin-right: 74px;
    }
    & > button:last-child {
      display: none;
    }
  }
}
.inventory-inventory {
  .search-container {
    .ant-input-group.ant-input-group-compact {
      line-height: 32px;
    }
  }
  .search-location {
    width: 232px !important;
    background: #fff !important;
    margin-right: 10px !important;
  }
}
