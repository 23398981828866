.page {
  position: relative;
  width: 100%;
  height: 100%;
  .chart-wrapper{
    height: 300px;
    // background: grey;
    position: absolute;
    left: 0;
    right: 0;
  }
  .table-wrapper{
    position: absolute;
    top: 350px;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
