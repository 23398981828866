.whatif {
  height: 100%;
  width: 100%;
  .search {
    width: 300px;
  }
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  .button {
    margin: 0 10px;
  }
}
.loading {
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  z-index: 999;
}
.log {
  white-space: pre-wrap;
}
