.sales-order-modal {
  .ant-modal-footer {
    & > button:first-child {
      margin-right: 74px;
    }
    & > button:last-child {
      display: none;
    }
  }
}
.sales-order {
  .dealy {
    position: relative;
  }
}
