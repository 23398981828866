body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // overflow: hidden;
  #root {
    height: 100%;
    width: 100%;
    position: relative;
    .userlayout {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      .layout {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        .side-bar {
          width: 220px;
          background: #404b5b;
        }
        .layout-right {
          height: 100%;
          flex: 1;
          .page {
            height: 100%;
            width: 100%;
          }
          .layout-content {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            .content-header {
              // height: 200px;
            }
            .content-body {
              flex: 1;
              overflow: auto;
              // background: yellow;
              .ant-table {
                height: 100%;
              }
              .ant-table-container {
                // border-right: 1px solid #f0f0f0;
              }
              .ant-table-wrapper {
                height: calc(100% - 50px) !important;
                border-bottom: 1px solid #f0f0f0;
              }
              .ant-spin-nested-loading {
                height: 100% !important;
              }
              .ant-spin-container {
                height: 100% !important;
              }
              .ant-table-content {
                height: 100% !important;
              }
              .ant-table-container {
                height: 100% !important;
              }
              .ant-table-body {
                height: calc(100% - 38.63px) !important;
              }
            }
          }
        }
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.modal-list .search button.ant-input-search-button {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px !important;
  margin-left: 43px;
}
