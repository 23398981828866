.page {
  position: relative;
  width: 100%;
  height: 100%;
  .orders-bread {
    cursor: pointer;
  }
  .ant-table-cell {
    height: 30px;
  }
}
