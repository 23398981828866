.inventory-report {
  position: relative;
  height: 100%;
  .report-chart {
    // width: 500px;
    height: 200px;
  }
  .echart {
    // width: 450px !important;
    height: 200px !important;
  }
  .load-percent {
    position: absolute;
    bottom: 50%;
    left: 0;
    z-index: 99;
    width: 100%;
  }
  .echart_cont{
    width: auto;
    height: auto;
    overflow: hidden;
    display: flex
  }
  .echart_left{
    width: 100px;
    float: left;
    ul{
      background: #efefef;
      position: relative;
      li{
        height: 30px;
        line-height: 30px;
        text-align: left;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-bottom: none;
        padding: 0 5px;
        &:last-child{
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
  .echart_right{
    width: 1100px;
    height: 110px;
    float: left;
    overflow: auto;
    border-right: 1px solid #ccc;
    margin-left: -100px;
    ul{
      width: max-content;
      border: 1px solid #ccc;
      border-left: none;
      border-right: none;
      box-sizing: border-box;
      overflow-x: auto;
      li{
        width: auto;
        height: auto;
        overflow: hidden;
        div{
          height: 29.6px;
          width: 100px;
          padding: 0 5px;
          line-height: 29px;
          text-align: left;
          border: 1px solid #ccc;
          border-left: none;
          border-top: none;
          float: left;
          box-sizing: border-box;
        }
        &:last-child{
          div{
            border-bottom: none;
          }
        }
      }
    }
  }
}
.ant-pagination-options{
  display: none;
}