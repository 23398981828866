.chart-container{
    #chart{
        width: 893px;
        background: #fff;
        height: 300px;
        margin: 0 auto;
    }
    max-width: 100%;
    background: #fff;
    height: 300px;
}