.modal-list{
    .search-container{
        line-height: 32px;
    }
    .search{
        width: 300px;
        background: transparent;
        span.ant-input-group-addon{
            background: transparent;;
        }
        button.ant-input-search-button{
            height: 24px;
            padding: 0px 12px;
            font-size: 14px;
            border-radius: 24px !important;
            margin-left: 10px;
        }
    }
}
