.menu-wrapper{
  display: flex;
  flex-direction: row;
  .menu-card{
    margin: 20px 0;
  }
  .menu-item{
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    cursor: pointer;
    align-items: center;
    .icon-font{
      font-size: 40px;
      margin-bottom: 8px;
    }
  }
}
