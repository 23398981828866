.purchaseorder {
  .search {
    width: 300px;
  }
  .requireClass {
    width: 340px;
    .ant-select {
      width: 300px !important;
    }
  }
}
