.userlayout {
  // min-height: 100vh;

  .icon-box {
    width: 240px;
    height: 100%;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #f9f9f9;
    background: url('../../assets/imgs/bg.png') left no-repeat;
  }
  .icon {
    height: 40px;
    margin-right: 10px;
  }
  .aps {
    color: #f9f9f9;
    font-size: 16px;
    margin: 0 30px 0 50px;
  }
  .welcome {
    color: #ccc5d6;
    font-size: 16px;
    font-family: 'SimSun';
  }
  .ant-layout-header {
    background: rgb(80, 116, 238);
  }
  .ant-layout-header {
    padding: 0 20px 0 0;
  }
  .header {
    display: flex;
    height: 60px;
    position: relative;
    a {
      display: block;
      width: 100px;
      color: #fff;
      position: absolute;
      right: 0;
      top: 0;
    }
    .description {
      position: absolute;
      right: 430px;
      color: white;
      font-size: 16px;
    }
    .env-select {
      position: absolute;
      right: 200px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #414b5b;
  }
  .ant-menu.ant-menu-dark {
    background: #414b5b;
  }
  .menuicon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
  .site-layout-background {
    overflow-y: auto;
    background: #fff;
  }
}
