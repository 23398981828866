.modal-list {
  width: 100%;
  height: 100%;
}
.content-body {
  display: flex;
  .ant-table-wrapper {
    height: 100%;
  }
  .list-left {
    padding: 0 4px;
    width: 200px;
    margin: 0 20px 10px 0;
    border: 1px solid #f0f0f0;
    .classify-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      .classify-header {
        height: 40px;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .classify-content {
        flex: 1;
        overflow: auto;
        .classify-item {
          height: 26px;
          cursor: pointer;
          line-height: 26px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          &:hover {
            background: #e6f7ff;
          }
        }
      }
    }
  }
  .list-right {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .right-header {
      height: 50px;
      .search-group {
        display: inline-block;
        margin-right: 13px;
      }
    }
    .right-content {
      flex: 1;
      overflow: auto;
    }
  }
}
