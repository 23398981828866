.gantt-container {
  height: calc(100vh - 50px - 200px);
}
.custom_left_event {
  cursor: pointer;
}
/* .underline {
  text-decoration: underline;
  color: #399bff;
} */
