.login {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #f0f2f5;
  overflow: auto;
  .title {
    text-align: center;
    color: #292929;
    font-size: 24px;
    padding-top: 60px;
    padding-bottom: 80px;
    font-weight: 700;
  }
  .login-container {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    width: 800px;
    background: #fff;
    box-shadow: 0 4px 8px 0 #c8cfe4;
    margin: 0 auto;
  }
  .login-left {
    width: 400px;
  }
  .login-right {
    height: 500px;
    padding: 60px 60px;
    .login-tab {
      color: #5074ee;
      font-size: 16px;
      padding: 7px 0;
      text-align: center;
      border-bottom: 2px solid #5074ee;
    }
    .username,
    .password {
      width: 280px;
      height: 40px;
      line-height: 40px;
      outline: 0;
      padding: 0 15px;
      margin-bottom: 22px;
      touch-action: manipulation;
      border-radius: 4px;
      background: #e8f0fe;
      input {
        background: #e8f0fe;
      }
    }
    .ant-col-16 {
      margin-left: 0;
    }
    .submit-btn {
      width: 280px;
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      background-color: #5074ee;
      border-color: #5074ee;
      border-radius: 20px;
    }
  }
  form {
    margin-top: 30px;
  }
}
